import React, { useState, useRef } from "react";
import SmallCard from "../components/SmallCards";
import { useDrag } from "react-dnd";
import { useDrop } from "react-dnd";

const CardForm = ({ id, deleteForm, index,unique,deleteFromArrowList }) => {
  
  const [basket, setBasket] = useState([]);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleDesc = (e) => {
    setDesc(e.target.value);
  };

  const [{ isDragging }, dragRef] = useDrag({
    type: "card",
    item: { id, title, desc, index,unique },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ isOver }, dropRef] = useDrop({
    accept: "card",
    drop: (item, monitor) => {
      // Don't replace items with themselves
      if(item.unique===unique) return
      let inBacket = false;
      basket.forEach((item1) => {
        if (item1.unique === item.unique) inBacket = true;
      });
      if (!inBacket) {
        basket.push(item);
        setBasket(basket);
        deleteForm(item);
        deleteFromArrowList((item.unique).toString())
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  const deleteCard=()=>{
    const item={unique}
    deleteForm(item);
    deleteFromArrowList((item.unique).toString())
  }
  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));
  return (
    <div className="formContainer " id={unique} ref={dragDropRef}>
      
        <button onClick={deleteCard} className="crossBtn">X</button>
  <p className="text-center">Card No:{index}</p>
        <h6 className="text-center mt-2">Title </h6>
        <input
          style={{ width: "200px" }}
          className="text-center rounded-pill"
          placeholder="put the title"
          onChange={handleTitle}
          name="title"
        />
        <h6 className="text-center mt-2">Descripsion </h6>

        <textarea
          className="text-center rounded-pill"
          placeholder="put the description"
          onChange={handleDesc}
        ></textarea>
        <div className="basket my-2">
          {basket.map((item, index) => {
            return (
              <SmallCard key={index} title={item.title} desc={item.desc} />
            );
          })}
        </div>
      </div>
  );
};

export default CardForm;
