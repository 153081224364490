import React from 'react'

export default function SmallCards({title,desc}) {
    return (
        <div className="msgBox" style={{height:"80px",width:"80px",border:"1.5px solid red",margin:"10px"}}>
            <p className="text-center" style={{fontSize:"10px"}}>{title}</p>

            <p className="text-center" style={{fontSize:"10px"}}>{desc}</p>
        </div>
    )
}
